@use '@igo2/core/all-style';
@use '@igo2/core/layout';

@use 'style/reset.scss';
@use 'style/theme.scss';

:root {
  --sidenav-width: 400px;
}

.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
  opacity: 1;
  visibility: visible;
  pointer-events: initial;
}

.mat-mdc-tooltip-trigger {
  touch-action: auto !important;
}
.toast-title-red {
  color: red;
  font-size: 22px;
  font-weight: bold;
}

.toast-message-gray {
  color: rgb(68, 69, 70);
}
.toast-message-green-div {
  color: rgb(35, 85, 20);
}

.toast-message-ul-pad-inline-start-9prc {
  padding-inline-start: 9%;
}

.toast-message-a-blue {
  color: blue !important;
  text-decoration: underline;
  text-decoration-color: blue;
}

.toast-message-a-blue:hover {
  text-decoration: underline !important;
}
